<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationProvider name="Organization" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="division.org_id"
                                            :options="orgList"
                                            id="org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option disabled :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <ValidationProvider name="Division Name(En)" vid="division_name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="division_name"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('research_manage.division_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            plain
                                            v-model="division.division_name"
                                            id="division_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <ValidationProvider name="Division Name(Bn)" vid="division_name_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="division_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.division_name_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="division_name_bn"
                                        v-model="division.division_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { researchDivisionStore, researchDivisionUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        division: {
            org_id: '',
            division_code: '',
            division_name: '',
            division_name_bn: '',
            division_description: '',
            division_description_bn: ''
        },
        sectorList: []
    }
  },
  computed: {
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(div => {
            return { value: div.value, text: this.$i18n.locale === 'en' ? div.text_en : div.text_bn }
          })
    },
    projectList: function () {
      const objectData = this.$store.state.AgriResearch.commonObj.projectList.filter(el => el.status === 0)
      return objectData.map(project => {
            return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
          })
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getDivisionData()
        // Object.freeze(tmp)
        this.division = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getSectorList () {
        const sectorList = this.$store.state.AgriResearch.commonObj.sectorList.filter(e => e.status === 0 && e.project_id === this.division.project_info_id)
        this.sectorList = sectorList.map(sector => {
            return { value: sector.value, text: this.$i18n.locale === 'en' ? sector.text_en : sector.text_bn }
        })
    },
    getDivisionData () {
    const tmpData = this.$store.state.list.find(project => project.id === this.id)
    return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${researchDivisionUpdate}/${this.id}`, this.division)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, researchDivisionStore, this.division)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
