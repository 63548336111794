
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.research_division_entry') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
             <b-col lg="6" md="5" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.organization')"
                    label-for="org_id"
                >
                    <b-form-select
                        plain
                        id="org_id"
                        :options="orgList"
                        v-model="search.org_id"
                        placeholder=""
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
          <b-col lg="2" md="2" sm="12" xs="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.research_division') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org)="data">
                      <span class="capitalize">{{  orgList.find(e=>e.value===data.item.org_id).text }}</span>
                    </template>
                    <template v-slot:cell(division_name)="data">
                      {{ data.item.division_name }}
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      {{ data.item.division_name_bn }}
                    </template>
                    <!-- <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template> -->
                      <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <!-- <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                      <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchDivisionList, researchDivisionToggleStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        FormV
    },
    data () {
        return {
            datas: [],
            search: {
                name: '',
                org_id: '0',
                research_division_id: '0',
                sector_id: '0'
            },
            sectorByProjectList: []
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.map(item => {
                    return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
                })
        },
        formTitle () {
                    return (this.editItemId === 0) ? this.$t('research_manage.research_division') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.research_division') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.organization'), class: 'text-left' },
                { label: this.$t('research_manage.research_division'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'org' },
                    { key: 'division_name_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'org' },
                    { key: 'division_name' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    mounted () {
        core.index()
        if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search)
        }
        this.loadData()
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(agriResearchServiceBaseUrl, researchDivisionToggleStatus, item, 'agri_research', 'researchDivisionList')
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, researchDivisionList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                    const allData = this.$store.state.list
                    this.datas = JSON.parse(JSON.stringify(allData))
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataList (data) {
        const listData = data
        return listData
        }
    }
}
</script>
